import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => {
  return <div className={styles.Container}>{children}</div>
}

/**
 */
export const Title = ({ children }) => {
  return <dt className={styles.Title}>{children}</dt>
}

/**
 */
export const Description = ({ children }) => {
  return <dd className={styles.Description}>{children}</dd>
}
