import React from 'react'

import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>事業者の名称</Title>
    <Description>丸紅アークログ株式会社</Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>代表者又は通信販売に関する業務の責任者の氏名</Title>
    <Description>代表取締役　井谷　嘉宏</Description>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>住所</Title>
    <Description>
      〒105-0023 東京都港区芝浦1-3-3　浜松町ライズスクエア2階
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>電話番号</Title>
    <Description>03-6381-7270（代）</Description>
  </Container>
)

/**
 */
export const FifthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>メールアドレス</Title>
    <Description>info@arch-log.com</Description>
  </Container>
)

/**
 */
export const SixSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>商品の販売価格・サービスの対価</Title>
    <Description>各商品・サービスのご購入ページにて表示する価格</Description>
  </Container>
)

/**
 */
export const SevenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>対価以外に必要となる費用</Title>
    <Description>
      なし
      <br />
      ※ただし、インターネット接続料金その他の電気通信回線の通信に関する費用はお客様にて別途ご用意いただく必要があります（金額は、お客様が契約した各事業者が定める通り）。
    </Description>
  </Container>
)

/**
 */
export const EightSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>代金の支払時期</Title>
    <Description>
      当該サービスの利用規約（以下「有償サービス利用規約」という）に定める
    </Description>
  </Container>
)

/**
 */
export const NineSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>支払方法</Title>
    <Description>銀行振込</Description>
  </Container>
)

/**
 */
export const TenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>商品引渡しまたはサービス提供の時期</Title>
    <Description>代金決済手続きの完了確認後直ちに</Description>
  </Container>
)

/**
 */
export const ElevenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>返品・キャンセルに関する特約</Title>
    <Description>
      当社ウェブサイトで販売する商品・サービスについては、購入手続き完了後の返品またはキャンセルをお受けいたしません。なお、商品・サービスに瑕疵がある場合は、有償サービス利用規約の定めに従って対応します。商品がソフトウェアの場合、動作環境及び必要スペックはご購入ページで表示しています。特別な販売条件または提供条件がある商品またはサービスについては、各商品またはサービスの購入ページにおいて条件を表示します。
    </Description>
  </Container>
)
