import React from 'react'

import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Business name</Title>
    <Description>Marubeni Archlog Co., Ltd.</Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>
      Name of representative or person in charge of mail-order business
    </Title>
    <Description>Representative Director Yoshihiro Itani</Description>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Address</Title>
    <Description>
      105-0023　Tokyo　Shibaura, Minato-ku1-3-3 Hamamatsucho Rise Square 2nd
      floor
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Phone number</Title>
    <Description>03-6381-7270 (main)</Description>
  </Container>
)

/**
 */
export const FifthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Email address</Title>
    <Description>info@arch-log.com</Description>
  </Container>
)

/**
 */
export const SixSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Product sales price / service consideration</Title>
    <Description>
      Price displayed on the purchase page of each product / service
    </Description>
  </Container>
)

/**
 */
export const SevenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Expenses required other than consideration</Title>
    <Description>
      none
      <br />* However, Internet connection charges and other telecommunications
      line communication costs must be prepared separately by the customer (the
      amount is as determined by each operator contracted by the customer).
    </Description>
  </Container>
)

/**
 */
export const EightSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Payment time</Title>
    <Description>
      Established in the terms of use for the service (hereinafter referred to
      as "Terms of Use for Paid Services")
    </Description>
  </Container>
)

/**
 */
export const NineSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Payment method</Title>
    <Description>Bank transfer</Description>
  </Container>
)

/**
 */
export const TenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Timing of product delivery or service provision</Title>
    <Description>
      Immediately after confirming the completion of the payment procedure
    </Description>
  </Container>
)

/**
 */
export const ElevenSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Special contract for returns / cancellations</Title>
    <Description>
      We do not accept returns or cancellations for products and services sold
      on our website after the purchase procedure is completed. If there is a
      defect in the product or service, we will handle it in accordance with the
      provisions of the terms of use for paid services. If the product is
      software, the operating environment and required specifications are
      displayed on the purchase page. For products or services that have special
      terms of sale or offer, the terms are displayed on the purchase page for
      each product or service.
    </Description>
  </Container>
)
