import React from 'react'

import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import * as SectionJa from './Section.ja'
import * as SectionEn from './Section.en'

/**
 */
export const FirstSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.FirstSection {...props} />
    default:
      return <SectionJa.FirstSection {...props} />
  }
})

/**
 */
export const SecondSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.SecondSection {...props} />
    default:
      return <SectionJa.SecondSection {...props} />
  }
})

/**
 */
export const ThirdSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.ThirdSection {...props} />
    default:
      return <SectionJa.ThirdSection {...props} />
  }
})

/**
 */
export const FourthSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.FourthSection {...props} />
    default:
      return <SectionJa.FourthSection {...props} />
  }
})

export const FifthSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.FifthSection {...props} />
    default:
      return <SectionJa.FifthSection {...props} />
  }
})

export const SixSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.SixSection {...props} />
    default:
      return <SectionJa.SixSection {...props} />
  }
})

export const SevenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.SevenSection {...props} />
    default:
      return <SectionJa.SevenSection {...props} />
  }
})

export const EightSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.EightSection {...props} />
    default:
      return <SectionJa.EightSection {...props} />
  }
})

export const NineSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.NineSection {...props} />
    default:
      return <SectionJa.NineSection {...props} />
  }
})

export const TenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.TenSection {...props} />
    default:
      return <SectionJa.TenSection {...props} />
  }
})

export const ElevenSection = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'en':
      return <SectionEn.ElevenSection {...props} />
    default:
      return <SectionJa.ElevenSection {...props} />
  }
})
